<template>
    <el-drawer
        ref="fdrawer"
        title="菜单配置"
        :visible="showMenusDrawer"
        direction="rtl"
        :before-close="handleClose"
        size="80%"
        style="z-index:2000">
        <div class="box">
            <el-table
                :data="menuRule"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                border
                default-expand-all
                :tree-props="{children: 'children'}">
                    <el-table-column
                        prop="name"
                        label="菜单名称"
                        width="280">
                    </el-table-column>
                    <el-table-column
                        label="行为">
                        <template slot-scope="scope">
                            <template v-if="scope.row.event == 1">
                                <span>打开内部链接</span>
                                <span class="scope-link">{{scope.row.viewName}}</span>
                            </template>
                            <template v-if="scope.row.event == 2">
                                <span>打开外部链接</span>
                                <a class="scope-link" :href="scope.row.forwardUrl">{{scope.row.forwardUrl}}</a>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="状态"
                        width="80">
                        <template slot-scope="scope">
                            <el-switch 
                                v-model="scope.row.delFlag" 
                                :active-value='1'
                                :inactive-value='2'
                                @change="quickSaveState(scope.row)"
                            ></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="200">
                        <template slot-scope="scpoe">
                            <el-button type="text" @click="addMenus('添加子菜单', scpoe)">添加子菜单</el-button>
                            <el-button type="text" @click="addMenus('编辑菜单', scpoe)">编辑</el-button>
                            <el-button type="text" @click="deleteMenu(scpoe)">删除</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <el-button icon="el-icon-plus" type="text" @click="addMenus('添加新菜单')">添加菜单</el-button>
            <el-drawer :title="activeName" :visible.sync="showSecondDrawer" size=70% :append-to-body="true">
                <div class="box mgleft10">
                    <el-form :model="menuActiveModel" label-width="100px">
                            <el-form-item v-if="activeName == '添加子菜单' || (menuActiveModel.parentName != 'root' && menuActiveModel.parentName != '')" label="上级菜单">
                                <el-input v-model="menuActiveModel.parentName" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="菜单图标">
                                <el-popover
                                    placement="right"
                                    width="400"
                                    v-model="showIcons"
                                    >
                                    <div class="icons-box">
                                        <i v-for="iconName in icons" :class="iconName" :key="iconName" @click="setMenuIcon(iconName)" />
                                    </div>
                                    <i class="big-icon" :class="menuActiveModel.logoUrl"  slot="reference"></i>
                                </el-popover>
                            </el-form-item>
                            <el-form-item label="菜单名称">
                                <el-input v-model="menuActiveModel.name"></el-input>
                            </el-form-item>
                            <el-form-item label="操作事件">
                                <el-select v-model="menuActiveModel.event">
                                    <el-option label="打开内部链接" :value='1'></el-option>
                                    <el-option label="打开外部链接" :value='2'></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="menuActiveModel.event == '1'" label="呈现模型">
                                <el-cascader ref="viewCascader"  :props="{label:'name', value:'id' ,emitPath:false}" v-model="menuActiveModel.viewId" :options="viewOptions" :show-all-levels="false" @change="eventChange"></el-cascader>
                            </el-form-item>
                            <el-form-item v-if="menuActiveModel.event == '2'" label="跳转地址">
                                <el-input v-model="menuActiveModel.forwardUrl"></el-input>
                            </el-form-item>
                            <el-form-item label="状态">
                                <el-switch 
                                    v-model="menuActiveModel.delFlag" 
                                    :active-value='1'
                                    :inactive-value='2'  
                                ></el-switch>
                            </el-form-item>
                            <div class="tool">
                                <el-button type="primary" @click="saveMenu">保存</el-button><el-button @click="test">取消</el-button>
                            </div>
                    </el-form>
                </div>
            </el-drawer>
        </div>
    </el-drawer>
</template>
<script>
import { createMenusJson, getUrlParam } from "../../../components/SwDesigner/utils";
import http from "../../../utils/http"
import { icons } from "../config"

export default {
    name: "MenuConfigDrawer",
    props:["showMenusDrawer"],
    inject:["designer"],
    mounted(){
        this.$refs["fdrawer"].open = this.initMenuData;
    },
    computed:{
        _showParent(){
            return true;
        }
    },
    data(){
        return {
             testurl:`${process.env.VUE_APP_BASE_URL}/com.startwe.models.project.web.ProjectTemplateDesignController.findPageView.biz`,
             activeName:"",
             addIndex:-1,
             showSecondDrawer:false,
             show:false,
             menuRule:[],
             showIcons:false,
             activeScope:null,
             viewOptions:[],
             icons,
             defaultModel:{
                parentId:"",
                parentName:"",
                name:"",
                logoUrl:"el-icon-s-home",
                forwardUrl:"",
                event:"",
                id:"",
                state: 1,
                delFlag: 1,
                viewId:"",
                viewType:"",
                viewName:""
             },
             menuActiveModel:{
                parentId:"",
                parentName:"",
                name:"",
                logoUrl:"el-icon-s-home",
                forwardUrl:"",
                event:"1",
                id:"",
                state: 1,
                delFlag: 1,
                viewId:"",
                viewType:"",
                viewName:""
             }
        }
    },
    methods:{
        quickSaveState( row ){
            this.menuActiveModel = {...row};
            this.saveMenu();
        },
        eventChange(e){
            let node = this.$refs['viewCascader'].getCheckedNodes()[0].data;
            this.menuActiveModel.viewType = node.type;
            this.menuActiveModel.viewName = node.name;
        },
        test(){
            //this.activeScope.row.name = "测试成功";
            this.showSecondDrawer = false;
        },
        refreshRule(){
            let that = this;
            let currentRule = this.designer.activeRule;
            http({
                url: window.startWe.portal.extraAttrs.loadMenuDesignUrl + `&portalId=${getUrlParam('portalId')}`,
                method:"GET"
            }).then(function( res ){
                let menus = [];
                menus = res.menus[0].children.map( menu => {
                    return createMenusJson( menu );
                })
                currentRule.orgData = res.menus[0].children;
                currentRule.children = menus;
                console.log(JSON.stringify(currentRule)); 
                that.menuRule = res.menus[0].children;
            })
        },
        setMenuIcon(name){
            this.menuActiveModel.logoUrl = name;
            this.showIcons = false;
        },
        deleteMenu(scope){
            this.$confirm('确认删除？')
            .then(_ => {
                this.menuActiveModel.id = scope.row.id;
                this.menuActiveModel.name = scope.row.name
                this.menuActiveModel.delFlag = 3;
                this.saveMenu();
            })
            .catch(_ => {});
        },
        addMenus(name, scope){
            this.activeName = name;
            this.activeScope = scope;
            if (this.activeName == "添加子菜单"){
                this.menuActiveModel.parentId = scope.row.id;
                this.menuActiveModel.parentName = scope.row.name;
                this.menuActiveModel.logoUrl = "el-icon-s-home";
                this.menuActiveModel.event = "";
                this.menuActiveModel.id = "";
                this.menuActiveModel.name = "";
            } else if (this.activeName == "编辑菜单"){
                this.menuActiveModel = {...scope.row};
            } else {
                this.menuActiveModel = {...this.defaultModel}
            }
            this.showSecondDrawer = true;
        },
        saveMenu(){
            let that = this;
            http({
                url: window.startWe.portal.extraAttrs.saveMenuDesignUrl,
                method:"POST",
                data:{
                    projectMenuDetail:{
                        ...this.menuActiveModel,
                        projectId: window.startWe.portal.projectId,
                        portalId: getUrlParam('portalId')
                    }
                }
            }).then(function( res ){
                if(res.success){
                    that.refreshRule();
                    that.showSecondDrawer = false;
                }
            })
        },
        initMenuData(){
            let that = this;
            this.menuRule = this.designer.activeRule.orgData;
            http({
                method:"post",
                url: window.startWe.portal.extraAttrs.findPageViewUrl,
                data:{
                   "projectId": window.startWe.portal.projectId
                }
            }).then(res => {
                console.log('initMenuData cascader', res)
                that.viewOptions = res.projectDesign;
            })

        },
        handleClose(){
            //验证配置是否保存
            this.$emit('update:showMenusDrawer', false)
        }
    }
}
</script>
<style scoped>
    .box{
        padding:24px;
    }

    .scope-link{
        margin-left:10px;
    }

    .icons-box{
        width:380px;
    }

    .icons-box i{
        padding:20px;
        font-size: 24px;
        margin:5px 10px 10px 0;
        border-radius: 4px;
    }

    .icons-box i:hover{
        background-color: bisque;
        cursor: pointer;
    }
    
    .mgleft10{
        margin-right: 20px;
    }

    .tool{
        margin:0 20px;
    }
    .el-popover{
        height:500px; 
        overflow-y: auto;
    }
    
    .big-icon{
        font-size: 24px;
        border:1px solid #ccc;
        padding:10px;
        border-radius: 4px;
    }
</style>
<style>
    .el-popover{
        height:500px; 
        overflow-y: auto;
    }
    
</style>