<template>
  <div class="main" v-loading="loading">
    <div class="header">
      <div class="nav">
        <div class="logo-m">
          <span>S</span>
        </div>
        <ul>
          <li><a href="#">项目名称</a></li>
          <li><a href="#">门户设计</a></li>
          <li class="current"><a href="#">起微网络</a></li>
        </ul>
      </div>

      <div class="toolbtns">
        <button type="button" class="s-button is-plain" @click="test">
          <span>预览</span>
        </button>
        <button
          type="button"
          class="s-button s-button--primary"
          @click="formSave"
        >
          <span>保存</span>
        </button>
      </div>
    </div>

    <div class="container">
      <div class="left-box">
        <div class="tabs">
          <div class="tabs-item current">
            <div class="s-icon-takeon"></div>
            <div>呈现</div>
          </div>
        </div>
        <div class="action-area">
          <div class="action-tabs">
            <div
              class="action-tabs-item "
              onclick="document.querySelector('.active-bar').style='transform: translateX(0px);'"
            >
              呈现
            </div>
            <div
              class="action-tabs-item current"
              onclick="document.querySelector('.active-bar').style='transform: translateX(77px);'"
            >
              组件
            </div>
            <div
              class="action-tabs-item"
              onclick="document.querySelector('.active-bar').style='transform: translateX(154px);'"
            >
              字段
            </div>
            <div class="active-bar"></div>
          </div>
          <div class="page-search">
            <input placeholder="搜索关键词..." />
          </div>
          <div class="page-list">
            <leftComponentsMenuNav></leftComponentsMenuNav>
          </div>
        </div>
      </div>

      <div class="design-area">
        <div class="toolbar">
          <div class="tabs">
            <!-- <div class="tab current">
              <span class="label">标签页1</span>
              <span class="icon s-icon-tabmore-active"></span>
            </div>
            <div class="tab">
              <span class="label">标签页2</span>
              <span class="icon s-icon-tabmore"></span>
            </div>
            <div class="tab">
              <span class="label">标签页3</span>
              <span class="icon s-icon-tabmore"></span>
            </div> -->
          </div>

          <div class="right-btn">
            <div class="btn">
              <span class="s-icon s-icon-16  s-icon-import-act"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-export"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-revoke"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-forward"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-daily"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-delete"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-ruler"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-pc"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-phone"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-attribute"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-theme"></span>
            </div>
            <div class="btn">
              <span class="s-icon s-icon-16 s-icon-setting"></span>
            </div>
          </div>
        </div>

        <div class="design-middle page-design-content  scrollbar">
          <div class="_fc-m-drag">
            <component
              :is="FormCreate"
              :rule="dragForm.rule"
              :option="form.value"
              v-model="dragForm.api"
            ></component>
          </div>
        </div>
      </div>

      <div class="right-detail">
        <div class="pagetype">
          页面/页面内容
        </div>
        <div class="detail-tabs">
          <div class="detail-tabs-item">
            属性
          </div>
          <div class="detail-tabs-item">
            样式
          </div>
          <div class="detail-tabs-item">
            高级
          </div>
          <div class="aline"></div>
        </div>

        <div class="right-sader-content">
          <ElAside class="_fc-r" width="230px">
            <ElContainer style="height: 100%;">
              <el-header height="40px" class="_fc-r-tabs">
                <div
                  class="_fc-r-tab"
                  :class="{ active: activeTab === 'props' }"
                  v-if="!!activeRule"
                  @click="activeTab = 'props'"
                >
                  组件配置
                </div>
                <div
                  class="_fc-r-tab"
                  :class="{ active: activeTab === 'form' && !!activeRule }"
                  @click="activeTab = 'form'"
                >
                  表单配置
                </div>
              </el-header>
              <ElMain v-show="activeTab === 'form'">
                <component
                  :is="FormCreate"
                  :rule="form.rule"
                  :option="form.option"
                  :value.sync="form.value.form"
                ></component>
              </ElMain>
              <ElMain
                v-show="activeTab === 'props'"
                style="padding: 0 20px;"
                :key="activeRule ? activeRule._id : ''"
              >
                <div>
                  <ElDivider v-if="showBaseRule">基础</ElDivider>
                  <component
                    :is="FormCreate"
                    v-show="showBaseRule"
                    v-model="baseForm.api"
                    :rule="baseForm.rule"
                    :option="baseForm.options"
                    @change="baseChange"
                  ></component>
                  <ElDivider>属性</ElDivider>
                  <component
                    :is="FormCreate"
                    v-model="propsForm.api"
                    :rule="propsForm.rule"
                    :option="propsForm.options"
                    @change="propChange"
                    @removeField="propRemoveField"
                  ></component>
                  <ElDivider v-if="showBaseRule">验证</ElDivider>
                  <component
                    :is="FormCreate"
                    v-show="showBaseRule"
                    v-model="validateForm.api"
                    :rule="validateForm.rule"
                    :option="validateForm.options"
                    @update:value="validateChange"
                  ></component>
                </div>
              </ElMain>
            </ElContainer>
          </ElAside>
        </div>
      </div>
    </div>
    <MenuConfigDrawer
      :showMenusDrawer.sync="showMenusDrawer"
    ></MenuConfigDrawer>
    <el-dialog title="修改页面名称" :visible.sync="showHeaderEdit">
      <el-input v-model="headerTitle"></el-input>
      <br />
      <br />
      <el-button @click="changeHeaderTitle" type="primary">修改</el-button>
    </el-dialog>
  </div>
</template>
<script>
import "@/assets/css/design.css";
import "@/assets/css/swform.css";

// import form from "../config/base/form";
import form from "../../components/SwDesigner/config/base/form";
import field from "../../components/SwDesigner/config/base/field";
import validate from "../../components/SwDesigner/config/base/validate";
import is, { hasProperty } from "@form-create/utils/lib/type";
import { deepCopy } from "@form-create/utils/lib/deepextend";
import { designerForm } from "../../components/SwDesigner/utils/form";
import { lower } from "@form-create/utils/lib/tocase";
import { upper } from "../../components/SwDesigner/utils/index";
import leftComponentsMenuNav from "./components/leftComponentsMenuNav.vue";
import MenuConfigDrawer from "../design/components/menuConfigDrawer.vue";
import ruleList from "../../components/SwDesigner/config/rule";
import createMenu from "../../components/SwDesigner/config/menu";
import http from "../../utils/http.js";
import { layout } from "../design/config";

export default {
  name: "DesignLayout",
  components: {
    leftComponentsMenuNav,
    MenuConfigDrawer,
  },
  provide() {
    return {
      fcx: {
        active: null,
      },
      designer: this,
    };
  },
  created() {
    window._designer = this;
  },
  mounted() {
    let that = this;
    http({
      url: this.$route.query.loadProjectPortalUrl,
      method: "GET",
    }).then((res) => {
      window.startWe = { portal: res.portal };
      let OrgRules = JSON.parse(window.startWe.portal.content || "[]");
      if (OrgRules && OrgRules.length) {
        that.setRule(OrgRules);
      } else if (!OrgRules.length) {
        that.setRule(layout[that.$route.query.setlayout * 1 || 0]);
      }
      this.loading = false;
    });
  },
  methods: {
    test() {
      console.log("test", this.getOption());
    },
    formSave() {
      let that = this;
      http({
        url: window.startWe.portal.extraAttrs.savePortalDesignUrl,
        method: "post",
        data: {
          projectPortal: {
            id: window.startWe.portal.id,
            name: window.startWe.portal.name,
            content: JSON.stringify(this.getRule()),
            delFlag: "1",
            projectId: window.startWe.portal.projectId,
          },
        },
      }).then(function(res) {
        if (res.success) {
          that.$message("保存成功");
        }
      });
    },
    addMenu(config) {
      if (!config.name || !config.list) return;
      let flag = true;
      this.menuList.forEach((v, i) => {
        if (v.name === config.name) {
          this.$set(this.menuList, i, config);
          flag = false;
        }
      });
      if (flag) {
        this.menuList.push(config);
      }
    },
    removeMenu(name) {
      [...this.menuList].forEach((v, i) => {
        if (v.name === name) {
          this.menuList.splice(i, 1);
        }
      });
    },
    setMenuItem(name, list) {
      this.menuList.forEach((v) => {
        if (v.name === name) {
          v.list = list;
        }
      });
    },
    appendMenuItem(name, item) {
      this.menuList.forEach((v) => {
        if (v.name === name) {
          v.list.push(item);
        }
      });
    },
    removeMenuItem(item) {
      this.menuList.forEach((v) => {
        let idx;
        if (is.String(item)) {
          [...v.list].forEach((menu, idx) => {
            if (menu.name === item) {
              v.list.splice(idx, 1);
            }
          });
        } else {
          if ((idx = v.list.indexOf(item)) > -1) {
            v.list.splice(idx, 1);
          }
        }
      });
    },
    addComponent(data) {
      if (Array.isArray(data)) {
        data.forEach((v) => {
          ruleList[v.name] = v;
        });
      } else {
        ruleList[data.name] = data;
      }
    },
    dragStart(children) {
      this.moveRule = children;
      this.added = false;
    },
    dragUnchoose(children, evt) {
      this.addRule = {
        children,
        oldIndex: evt.oldIndex,
      };
    },
    getParent(rule) {
      let parent = rule.__fc__.parent.rule;
      const config = parent.config;
      if (config && config.config.inside) {
        rule = parent;
        parent = parent.__fc__.parent.rule;
      }
      return { root: parent, parent: rule };
    },
    makeDrag(group, tag, children, on, subRule) {
      return {
        type: "DragBox",
        wrap: {
          show: false,
        },
        col: {
          show: false,
        },
        inject: true,
        props: {
          rule: {
            props: {
              tag: "el-col",
            },
            attrs: {
              group: group === true ? "default" : group,
              ghostClass: "ghost",
              animation: 150,
              handle: "._fc-drag-btn",
              emptyInsertThreshold: 0,
              direction: "vertical",
            },
          },
          subRule: subRule || {
            props: {
              name: "fade",
              tag: "div",
            },
          },
          tag,
        },
        children,
        on,
      };
    },
    clearDragRule() {
      this.setRule([]);
    },
    makeDragRule(children) {
      return [
        this.makeDrag(
          true,
          "draggable",
          children,
          {
            add: (inject, evt) => this.dragAdd(children, evt),
            end: (inject, evt) => this.dragEnd(children, evt),
            start: (inject, evt) => this.dragStart(children, evt),
            unchoose: (inject, evt) => this.dragUnchoose(children, evt),
          },
          {
            props: {
              name: "fade",
              tag: "div",
            },
          }
        ),
      ];
    },
    previewFc() {
      this.preview.state = true;
      this.preview.rule = this.getRule();
      this.preview.option = this.getOption();
    },
    getRule() {
      return this.parseRule(deepCopy(this.dragForm.api.rule[0].children));
    },
    getJson() {
      return designerForm.toJson(this.getRule());
    },
    getOption() {
      const option = deepCopy(this.form.value);
      option.submitBtn = option.form.formCreateSubmitBtn;
      option.resetBtn = option.form.formCreateResetBtn;
      delete option.form.formCreateSubmitBtn;
      delete option.form.formCreateResetBtn;
      return option;
    },
    setRule(rules) {
      const children = this.loadRule(
        is.String(rules) ? designerForm.parseJson(rules) : rules
      );
      this.children = children;
      this.clearActiveRule();
      this.dragForm.rule = this.makeDragRule(children);
    },
    clearActiveRule() {
      this.activeRule = null;
      this.activeTab = "form";
    },
    setOption(data) {
      let option = { ...data };
      option.form.formCreateSubmitBtn = !!option.submitBtn;
      option.form.formCreateResetBtn = !!option.resetBtn;
      option.submitBtn = false;
      delete option.resetBtn;
      this.form.value = option;
    },
    loadRule(rules) {
      const loadRule = [];
      rules.forEach((rule) => {
        if (is.String(rule) || rule._fc_parse === false) {
          return loadRule.push(rule);
        }
        const config = ruleList[rule._fc_drag_tag] || ruleList[rule.type];
        const _children = rule.children;
        rule.children = [];
        if (rule.control) {
          rule._control = rule.control;
          delete rule.control;
        }
        if (config) {
          rule = this.makeRule(config, rule);
          if (_children) {
            let children = rule.children[0].children;
            if (config.drag) {
              children = children[0].children;
            }
            children.push(...this.loadRule(_children));
          }
        } else if (_children) {
          rule.children = this.loadRule(_children);
        }
        loadRule.push(rule);
      });
      return loadRule;
    },
    parseRule(children) {
      return [...children].reduce((initial, rule) => {
        if (is.String(rule)) {
          initial.push(rule);
          return initial;
        } else if (rule.type === "DragBox") {
          initial.push(...this.parseRule(rule.children));
          return initial;
        } else if (rule.type === "DragTool") {
          rule = rule.children[0];
          if (rule.type === "DragBox") {
            initial.push(...this.parseRule(rule.children));
            return initial;
          }
        }
        if (!rule) return initial;
        rule = { ...rule };
        if (rule.children.length) {
          rule.children = this.parseRule(rule.children);
        }

        delete rule._id;
        if (rule.config) {
          delete rule.config.config;
        }
        if (rule.effect) {
          delete rule.effect._fc;
          delete rule.effect._fc_tool;
        }
        if (rule._control) {
          rule.control = rule._control;
          delete rule._control;
        }
        Object.keys(rule)
          .filter(
            (k) =>
              (Array.isArray(rule[k]) && rule[k].length === 0) ||
              (is.Object(rule[k]) && Object.keys(rule[k]).length === 0)
          )
          .forEach((k) => {
            delete rule[k];
          });
        initial.push(rule);
        return initial;
      }, []);
    },
    baseChange(field, value, _, fapi) {
      if (this.activeRule && fapi[this.activeRule._id] === this.activeRule) {
        this.$set(this.activeRule, field, value);
      }
    },
    propRemoveField(field, _, fapi) {
      if (this.activeRule && fapi[this.activeRule._id] === this.activeRule) {
        this.dragForm.api.sync(this.activeRule);
        if (field.indexOf("formCreate") === 0) {
          field = field.replace("formCreate", "");
          if (!field) return;
          field = lower(field);
          if (field.indexOf("effect") === 0 && field.indexOf(">") > -1) {
            this.$delete(this.activeRule.effect, field.split(">")[1]);
          } else if (field.indexOf("props") === 0 && field.indexOf(">") > -1) {
            this.$delete(this.activeRule.props, field.split(">")[1]);
          } else if (field === "child") {
            this.$delete(this.activeRule.children, 0);
          } else if (field) {
            this.$set(this.activeRule, field, undefined);
          }
        } else {
          this.$delete(this.activeRule.props, field);
        }
      }
    },
    propChange(field, value, _, fapi) {
      if (this.activeRule && fapi[this.activeRule._id] === this.activeRule) {
        if (field.indexOf("formCreate") === 0) {
          field = field.replace("formCreate", "");
          if (!field) return;
          field = lower(field);
          if (field.indexOf("effect") === 0 && field.indexOf(">") > -1) {
            this.$set(this.activeRule.effect, field.split(">")[1], value);
          } else if (field.indexOf("props") === 0 && field.indexOf(">") > -1) {
            this.$set(this.activeRule.props, field.split(">")[1], value);
          } else if (field === "child") {
            this.$set(this.activeRule.children, 0, value);
          } else {
            this.$set(this.activeRule, field, value);
          }
        } else {
          this.$set(this.activeRule.props, field, value);
        }
      }
    },
    validateChange(formData) {
      if (
        !this.activeRule ||
        this.validateForm.api[this.activeRule._id] !== this.activeRule
      )
        return;
      this.activeRule.validate = formData.validate || [];
      this.dragForm.api.refreshValidate();
      this.dragForm.api.nextTick(() => {
        this.dragForm.api.clearValidateState(this.activeRule.field);
      });
    },
    toolActive(rule) {
      if (this.activeRule) {
        delete this.propsForm.api[this.activeRule._id];
        delete this.baseForm.api[this.activeRule._id];
        delete this.validateForm.api[this.activeRule._id];
      }
      this.activeRule = rule;

      this.$nextTick(() => {
        this.activeTab = "props";
        this.$nextTick(() => {
          this.propsForm.api[this.activeRule._id] = this.activeRule;
          this.baseForm.api[this.activeRule._id] = this.activeRule;
          this.validateForm.api[this.activeRule._id] = this.activeRule;
        });
      });

      if (!this.cacheProps[rule._id]) {
        this.cacheProps[rule._id] = rule.config.config.props(rule);
      }

      this.propsForm.rule = this.cacheProps[rule._id];

      const formData = { ...rule.props, formCreateChild: rule.children[0] };
      Object.keys(rule).forEach((k) => {
        if (["effect", "config", "payload", "id", "type"].indexOf(k) < 0)
          formData["formCreate" + upper(k)] = rule[k];
      });
      ["props", "effect"].forEach((name) => {
        rule[name] &&
          Object.keys(rule[name]).forEach((k) => {
            formData["formCreate" + upper(name) + ">" + k] = rule[name][k];
          });
      });
      this.propsForm.options.formData = formData;

      this.showBaseRule = hasProperty(rule, "field") && rule.input !== false;

      if (this.showBaseRule) {
        this.baseForm.options.formData = {
          field: rule.field,
          title: rule.title || "",
          info: rule.info,
          _control: rule._control,
        };

        this.validateForm.options.formData = {
          validate: rule.validate ? [...rule.validate] : [],
        };
      }
    },
    dragAdd(children, evt) {
      const newIndex = evt.newIndex;
      const menu = evt.item._underlying_vm_;
      if (!menu) {
        if (this.addRule) {
          const rule = this.addRule.children.splice(this.addRule.oldIndex, 1);
          children.splice(newIndex, 0, rule[0]);
        }
      } else {
        const rule = this.makeRule(ruleList[menu.name]);
        children.splice(newIndex, 0, rule);
      }
      this.added = true;
    },
    dragEnd(children, { newIndex, oldIndex }) {
      if (
        !this.added &&
        !(this.moveRule === children && newIndex === oldIndex)
      ) {
        const rule = this.moveRule.splice(oldIndex, 1);
        children.splice(newIndex, 0, rule[0]);
      }
      this.moveRule = null;
      this.addRule = null;
      this.added = false;
    },
    makeRule(config, _rule) {
      const rule = _rule || { ...config.rule(), ...config._newRule };
      if (rule._fc_parse === false) {
        return rule;
      }
      rule.config = { config };
      if (!rule.effect) rule.effect = {};
      rule.effect._fc = true;
      rule._fc_drag_tag = config.name;

      let drag;

      if (config.drag) {
        const children = [];
        rule.children.push(
          (drag = this.makeDrag(config.drag, rule.type, children, {
            end: (inject, evt) => this.dragEnd(inject.self.children, evt),
            add: (inject, evt) => this.dragAdd(inject.self.children, evt),
            start: (inject, evt) => this.dragStart(inject.self.children, evt),
            unchoose: (inject, evt) =>
              this.dragUnchoose(inject.self.children, evt),
          }))
        );
      }
      
      if (config.children && !_rule) {
        //如果children是以字符串形式设置者直接在注册的组件库中查找对应的配置
        if(typeof config.children === 'string'){
          config.children.split(',').forEach( _child => {
            const child = this.makeRule(ruleList[_child]);
            (drag || rule).children.push(child);
          })
        } else {
          //如果是以数组传入则在children中直接添加配置内容
          config.children.forEach( _child => {
            const child = this.makeRule(_child);
            (drag || rule).children.push(child);
          })
        }
        
      }

      const mask =
        this.mask !== undefined ? this.mask !== false : config.mask !== false;

      if (config.inside) {
        rule.children = [
          {
            type: "DragTool",
            props: {
              dragBtn: config.dragBtn !== false,
              children: config.children,
              mask,
            },
            effect: {
              _fc_tool: true,
            },
            inject: true,
            on: {
              delete: ({ self }) => {
                const parent = this.getParent(self).parent;
                parent.__fc__.rm();
                this.$emit("delete", parent);
                this.clearActiveRule();
              },
              add: ({ self }) => {
                const top = this.getParent(self);
                this.$emit("add", top.parent);
                top.root.children.splice(
                  top.root.children.indexOf(top.parent) + 1,
                  0,
                  this.makeRule(top.parent.config.config)
                );
              },
              addChild: ({ self }) => {
                const top = this.getParent(self);
                const config = top.parent.config.config;
                const item = ruleList[config.children];
                if (!item) return;
                (!config.drag
                  ? top.parent
                  : top.parent.children[0]
                ).children[0].children.push(this.makeRule(item));
              },
              copy: ({ self }) => {
                const top = this.getParent(self);
                this.$emit("copy", top.parent);
                top.root.children.splice(
                  top.root.children.indexOf(top.parent) + 1,
                  0,
                  designerForm.copyRule(top.parent)
                );
              },
              active: ({ self }) => {
                const top = this.getParent(self);
                this.$emit("active", top.parent);
                this.toolActive(top.parent);
              },
            },
            children: rule.children,
          },
        ];
        return rule;
      } else {
        return {
          type: "DragTool",
          props: {
            dragBtn: config.dragBtn !== false,
            children: config.children,
            mask,
          },
          effect: {
            _fc_tool: true,
          },
          inject: true,
          on: {
            delete: ({ self }) => {
              this.$emit("delete", self.children[0]);
              self.__fc__.rm();
              this.clearActiveRule();
            },
            add: ({ self }) => {
              this.$emit("add", self.children[0]);
              const top = this.getParent(self);
              top.root.children.splice(
                top.root.children.indexOf(top.parent) + 1,
                0,
                this.makeRule(self.children[0].config.config)
              );
            },
            addChild: ({ self }) => {
              const config = self.children[0].config.config;
              const item = ruleList[config.children];
              if (!item) return;
              (!config.drag
                ? self
                : self.children[0]
              ).children[0].children.push(this.makeRule(item));
            },
            copy: ({ self }) => {
              this.$emit("copy", self.children[0]);
              const top = this.getParent(self);
              top.root.children.splice(
                top.root.children.indexOf(top.parent) + 1,
                0,
                designerForm.copyRule(top.parent)
              );
            },
            active: ({ self }) => {
              this.$emit("active", self.children[0]);
              this.toolActive(self.children[0]);
            },
          },
          children: [rule],
        };
      }
    },
    changeHeaderTitle() {
      let currentRule = this.activeRule;
      let _children = [...currentRule.children];
      _children[0].children[0] = this.headerTitle;
      currentRule.children = _children;
      this.showHeaderEdit = false;
    },
  },
  data() {
    const children = [];
    return {
      loading: true,
      FormCreate: designerForm.$form(),
      cacheProps: {},
      moveRule: null,
      addRule: null,
      added: null,
      activeTab: "form",
      activeRule: null,
      children,
      menuList: this.menu || createMenu(),
      showBaseRule: false,
      showHeaderEdit: false,
      headerTitle: "",
      visible: {
        preview: false,
      },
      preview: {
        state: false,
        rule: [],
        option: {},
      },
      dragForm: {
        rule: this.makeDragRule(children),
        api: {},
      },
      form: {
        rule: form(),
        option: {
          form: {
            labelPosition: "top",
            size: "mini",
          },
          submitBtn: false,
        },
        value: {
          form: {
            inline: false,
            hideRequiredAsterisk: false,
            labelPosition: "right",
            size: "mini",
            labelWidth: "125px",
            formCreateSubmitBtn: true,
            formCreateResetBtn: false,
          },
          submitBtn: false,
        },
      },
      baseForm: {
        rule: field(),
        api: {},
        options: {
          form: {
            labelPosition: "top",
            size: "mini",
          },
          submitBtn: false,
          mounted: (fapi) => {
            fapi.activeRule = this.activeRule;
            fapi.setValue(fapi.options.formData || {});
          },
        },
      },
      validateForm: {
        rule: validate(),
        api: {},
        options: {
          form: {
            labelPosition: "top",
            size: "mini",
          },
          submitBtn: false,
          mounted: (fapi) => {
            fapi.activeRule = this.activeRule;
            fapi.setValue(fapi.options.formData || {});
          },
        },
      },
      propsForm: {
        rule: [],
        api: {},
        options: {
          form: {
            labelPosition: "top",
            size: "mini",
          },
          submitBtn: false,
          mounted: (fapi) => {
            fapi.activeRule = this.activeRule;
            fapi.setValue(fapi.options.formData || {});
          },
        },
      },
      showMenusDrawer: false,
    };
  },
  watch: {
    // headerTitle(val){
    //   let currentRule = {...this.activeRule};
    //   console.log(currentRule)
    //   currentRule.orgData = val;
    //   currentRule.children[0].children[0] = val;
    //   this.activeRule = currentRule;
    // }
  },
};
</script>
<style scoped>
.action-area .action-tabs .active-bar {
  height: 2px;
  width: 25px;
  background-color: #3491fa;
  position: absolute;
  left: 26px;
  bottom: -1px;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}

.main > .container .right-detail .detail-tabs .active-bar {
  height: 2px;
  width: 25px;
  background-color: #3491fa;
  position: absolute;
  left: 26px;
  bottom: -2px;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
</style>
